import { PageDocumentSlugOnly, PageTypeToPathFunction } from './types/types';
import { urlJoin } from './utils/utils';

export const AUTHOR_NAME = 'Hook Point';
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const PLANS_TO_PRICES = {
  monthly: {
    current: 14,
    beforeDiscount: 28,
  },
  yearly: {
    current: 140,
    beforeDiscount: 280,
  },
} as const;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = [DEFAULT_LANG] as const;

// WARNING When updating these three constants update in cms constants too
// WARNING When updating these three constants update in site create_redirects_file_from_cms too
export const ARTICLES_PREFIX_URL = 'articles';

export const PAGE_DOCUMENT_TYPES = ['page', 'article'] as const;

export const PAGE_TYPE_TO_PATH_FUNCTION = {
  page: (pageDoc: PageDocumentSlugOnly) => urlJoin(pageDoc.slug.current),
  article: (articleDoc: PageDocumentSlugOnly) =>
    urlJoin(ARTICLES_PREFIX_URL, articleDoc.slug.current),
} as const satisfies PageTypeToPathFunction;

export const STRIPE_MONTHLY_PLAN_PRICE_ID = process.env.GATSBY_STRIPE_MONTHLY_PLAN_PRICE_ID!;
export const STRIPE_YEARLY_PLAN_PRICE_ID = process.env.GATSBY_STRIPE_YEARLY_PLAN_PRICE_ID!;
export const NETLIFY_FUNCTIONS_BASE_URL = process.env.GATSBY_NETLIFY_FUNCTIONS_BASE_URL!;

export const STRIPE_CUSTOMER_PORTAL_URL = process.env.GATSBY_STRIPE_CUSTOMER_PORTAL_URL!;

/**
 *
 * Below here are constants we normally don't need to change or check
 *
 */

export const USE_COOKIES_BANNER = process.env.GATSBY_USE_COOKIES_BANNER;

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || 'null.com';

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || 'https://www.null.com';

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;
